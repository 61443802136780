import React from "react";

import ScrollProvider from "./src/context/ScrollContext";

export const wrapRootElement = ({ element }) => {
  if (typeof window !== "undefined"){
    window.localStorage.removeItem("filter")
  }
  return <ScrollProvider>{element}</ScrollProvider>;
};
