// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ausstellungen-tsx": () => import("./../../../src/pages/ausstellungen.tsx" /* webpackChunkName: "component---src-pages-ausstellungen-tsx" */),
  "component---src-pages-biografie-tsx": () => import("./../../../src/pages/biografie.tsx" /* webpackChunkName: "component---src-pages-biografie-tsx" */),
  "component---src-pages-fotos-tsx": () => import("./../../../src/pages/fotos.tsx" /* webpackChunkName: "component---src-pages-fotos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kunstsammlungen-tsx": () => import("./../../../src/pages/kunstsammlungen.tsx" /* webpackChunkName: "component---src-pages-kunstsammlungen-tsx" */),
  "component---src-pages-publikationen-tsx": () => import("./../../../src/pages/publikationen.tsx" /* webpackChunkName: "component---src-pages-publikationen-tsx" */),
  "component---src-pages-werkverzeichnis-tsx": () => import("./../../../src/pages/werkverzeichnis.tsx" /* webpackChunkName: "component---src-pages-werkverzeichnis-tsx" */),
  "component---src-templates-werk-template-tsx": () => import("./../../../src/templates/WerkTemplate.tsx" /* webpackChunkName: "component---src-templates-werk-template-tsx" */)
}

