import React, { useState } from "react";

export const ScrollContext = React.createContext();

export default function ScrollProvider({ children }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = pos => setScrollPosition(pos);

  return <ScrollContext.Provider value={{ scrollPosition, handleScroll }}>{children}</ScrollContext.Provider>;
}
